import React, { useState, useEffect } from 'react';
import './Login.css'; // 引入样式文件

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [apiBaseUrl, setApiBaseUrl] = useState('');

    // 加载配置文件
    useEffect(() => {
        fetch('/config.json') // 从 public 文件夹中加载 config.json
        .then((response) => {
            if (!response.ok) {
            throw new Error('Failed to load config');
            }
            return response.json();
        })
        .then((config) => {
            setApiBaseUrl(config.API_BASE_URL);
        })
        .catch((err) => console.error('Error loading config:', err));
    }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!apiBaseUrl) {
      setError('Configuration not loaded. Please try again.');
      return;
    }

    try {
        const response = await fetch(`${apiBaseUrl}/api/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
          credentials: 'include', // 确保发送和接收 Cookie
        });
        console.log(response)
        if (response.ok) {
          const data = await response.json();
          // 保存认证状态到 localStorage
          localStorage.setItem('isAuthenticated', 'true');
          localStorage.setItem('authToken', data.token); // 存储令牌
          window.location.href = data.redirectUrl; // 跳转到后端返回的页面
        } else {
          const errorData = await response.json();
          setError(errorData.message);
        }
      } catch (err) {
        setError('Network error. Please try again later.');
      }
    };

  return (
    <div className="login-container">
      <div className="login-box">
        {/* <h1 style={{ fontSize: '6rem', fontWeight: 'bold', letterSpacing: '2px', color: '#7D7D7D', margin: '0 0 20px 0' }}>
                    <span style={{ color: '#4285f4' }}>o</span>
                    <span style={{ color: '#ea4335' }}>h</span>
                    <span style={{ color: '#fbbc05' }}>h</span>
                    <span style={{ color: '#34a853' }}>o</span>
        </h1> */}
        <div id="logo-container">
          <img
              src="caixun.svg"
              alt="Logo"
              style={{ marginBottom: "30px", width: "250px" }}
          />
        </div>
        {/* <h2 className="login-title">Sign in</h2> */}
        <form onSubmit={handleLogin} className="login-form">
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="login-input"
                />
            </div>
            <div className="input-group">
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-input"
                />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
}